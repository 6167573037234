:root {
  /* Shared colors */
  --control-gradient: linear-gradient(-180deg, #8ae 0%, #47c 100%);
  --control-gradient-hover: linear-gradient(-180deg, #acf 0%, #47c 100%);

  /* Logical, doesn't need to be duplicated */
  --scrollbar-track-color: var(--main-background-color);
  --scrollbar-thumb-color: var(--text-background-color);
  --transition: 0.5s ease;
}

html {
  --border-color: light-dark(#222, #aaa);
  --button-text-color: #222;
  --input-background-color: light-dark(#fff, #fed);
  --link-color: light-dark(#25a, #69e);
  --link-color-visited: light-dark(#028, #58d);
  --main-background-color: light-dark(#114, #003);
  --text-background-color: light-dark(#ffeeddcc, #333333dd);
  --text-color: light-dark(#000, #fed);
  
  background-color: var(--main-background-color);
  color-scheme: light dark;
  transition: background var(--transition);
  
  &[data-color-scheme="light"] {
    color-scheme: light;
  }
  
  &[data-color-scheme="dark"] {
    color-scheme: dark;
  }
}

body {
  background-size: 900px 600px;
  background-repeat: repeat-x;
  background-image: light-dark(url("/static/images/layered-peaks-light.svg"), url("/static/images/layered-peaks-dark.svg"));
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
  transition: background var(--transition);

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
  }
}

.innerBody {
  background-color: var(--text-background-color);
  border: solid var(--border-color) 1px;
  color: var(--text-color);
  transition:
    background var(--transition),
    border var(--transition),
    color var(--transition);
}

header a {
  &, &:hover, &:link, &:visited {
    color: var(--text-color);
    transition: color var(--transition);
  }
}

a, a:link {
  color: var(--link-color);
  transition: color var(--transition);
}

a:hover, a:visited {
  color: var(--link-color-visited);
  transition: color var(--transition);
}

a[href^="http"]:not([href*="kgsoftwarellc.com"])::after {
  content: " ⎋";
}

#profile {
  filter: light-dark(brightness(1) contrast(1), brightness(0.8) contrast(1.2));
}

#profile, button, .linkButton, span.themeToggle {
  border: solid var(--border-color) 1px;
  box-shadow: 0.05rem 0.05rem 0.15rem #222222dd;
  transition: border var(--transition);
}

button, .linkButton, .linkButton:link, .linkButton:visited {
  background-image: var(--control-gradient);
  color: var(--button-text-color);
  transition: color var(--transition);
}

button:hover, .linkButton:hover {
  background-image: var(--control-gradient-hover);
  box-shadow: 0.25rem 0.25rem 0.25rem #222222dd;
  cursor: pointer;
  scale: 1.1;
  transition: all 0.2s ease-in-out;
  translate: -0.05rem -0.05rem;
}

blockquote {
  font-style: italic;
}
