form {
  margin: 0 0 1rem;

  & button {
    display: block;
    margin: 0 auto;
  }
}

label {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input {
  background-color: var(--input-background-color);
  border: none;
  border-radius: var(--content-border-radius);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  color: var(--button-text-color);
  margin-bottom: 2rem;
  padding: 0.5rem;
  width: 97%;
  transition: background-color var(--transition), color var(--transition);
}

fieldset {
  border: none;
  columns: auto;
  column-gap: 1rem;
  display: flex;
  margin: 0 0 1rem;
  padding: 0;
}

legend {
  margin-bottom: 0.25rem;
  padding: 0;
}

fieldset label {
  white-space: nowrap;
}

input[type="checkbox"] {
  cursor: pointer;
  margin: 0 0.5rem;
  width: auto;
}

@media (max-width: 760px) {
  fieldset {
    display: grid;
    grid-auto-columns: 50%;
    grid-template-areas: "a a";
  }
}
