:root {
  --content-border-radius: 0.25rem;
}

html {
  font-size: 16px;
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  padding: 1rem;
  position: relative;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
  }
}

.innerBody {
  border-radius: var(--content-border-radius);
  box-shadow: 0.25rem 0.25rem 0.25rem #222222dd;
  margin: 2rem auto;
  width: min-content;
}

header {
  font-size: 2rem;
  padding: 0.5em 3em;
  position: relative;
  width: fit-content;

  &::before, &::after {
    font-size: 5em;
    line-height: 1em;
    position: absolute;
    top: 1rem;
  }

  &::before {
    content: "{";
    left: 1.5rem;
  }

  &::after {
    content: "}";
    right: 1.5rem;
  }

  & h2 {
    font-size: 0.75em;
    margin: 0 auto 0.5em;
  }
}

h1 {
  margin: 2rem auto 0;
}

main {
  padding: 0 2rem 1rem;
}

footer {
  font-size: 0.75rem;
  padding: 0 2rem 2rem;
}

.heroCTAs {
  margin-bottom: 1rem;

  & .linkButton {
    margin: 0 1rem;
  }
}

#profile {
  float: left;
  margin: 0 1rem 1rem 0;
}

#profile, button, .linkButton, span.themeToggle {
  border-radius: var(--content-border-radius);
}

button, .linkButton, .linkButton:link, .linkButton:visited {
  display: inline-block;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.examples {
  clear: both;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin: 0 2rem 1rem;
}

ul {
  padding-left: 1rem;
}

.example {
  break-inside: avoid;

  & li {
    line-height: 1.1rem;
    padding-bottom: 0.75rem;
  }
}

.leadIntoNextBlock {
  margin-bottom: 0;
}

@media (max-width: 760px) {
  header {
    font-size: 1.5rem;
  }

  .innerBody {
    margin: 1rem auto;
  }

  main {
    padding: 0 1rem 1rem;

    & .examples {
      margin: 0 1rem;
    }
  }

  fieldset {
    columns: 2;
    display: block;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 525px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  body {
    padding: 0.5rem;
  }

  header {
    font-size: 1.25rem;
  }
}

@media (max-width: 375px) {
  body {
    padding: 0;
  }

  .innerBody {
    border: none;
    margin: 0;
    width: 100%;
  }

  header {
    padding: 0.5em 1em;
    margin: 0 auto;

    &::before {
      left: -0.5rem;
    }

    &::after {
      right: -0.5rem;
    }
  }

  #profile {
    height: 100px;
    width: 100px;
  }
}
