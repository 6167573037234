label.themeToggle {
  align-items: center;
  display: inline-grid;
  grid-template-areas: "input label";
  grid-template-columns: 4rem auto;
  justify-self: center;
}

#themeToggleCheckbox {
  opacity: 0;
}

#themeToggleCheckbox, span.themeToggle {
  font-size: 1.25rem;
  grid-area: input;
  height: 1.5rem;
  width: 4rem;
}

span.themeToggle {
  align-items: center;
  background-color: var(--input-background-color);
  border-radius: 1em;
  display: inline-grid;
  opacity: 0.8;

  &:before {
    --switch-color: var(--link-color);
    --switch-x: 5%;
    background-image: url("/static/images/sun.svg"), var(--control-gradient);
    border: 1px solid var(--border-color);
    border-radius: 50%;
    content: "";
    height: 1em;
    transform: translateX(var(--switch-x));
    transition: all var(--transition);
    width: 1em;
  }
}

input:checked + span.themeToggle:before {
  --switch-color: var(--link-color);
  --switch-x: 185%;
  background-image:
    url("/static/images/moon-star.svg"),
    var(--control-gradient);
}

input:focus + span.themeToggle:before {
  box-shadow: 0 0 0 3px var(--link-color-visited);
}

input:checked:focus + span.themeToggle:before {
  box-shadow: 0 0 0 3px var(--link-color);
}

label.themeToggle:hover input + span.themeToggle:before {
  background-image:
    url("/static/images/sun.svg"),
    var(--control-gradient-hover);
}

label.themeToggle:hover input:checked + span.themeToggle:before {
  background-image:
    url("/static/images/moon-star.svg"),
    var(--control-gradient-hover);
}
