@font-face {
  font-display: fallback;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  src: url("/static/fonts/PlayfairDisplay-VariableFont_wght-subset.ttf");
}

@font-face {
  ascent-override: 94%;
  font-family: "Playfair Display-fallback";
  line-gap-override: 15%;
  size-adjust: 102.5%;
  src: local("Times New Roman");
}

body, button, input, option {
  font-family:
    ui-rounded,
    "Hiragino Maru Gothic ProN",
    Quicksand,
    Comfortaa,
    Manjari,
    "Arial Rounded MT Bold",
    Calibri,
    source-sans-pro,
    sans-serif;
}

header {
  font-family: "Playfair Display", "Playfair Display-fallback";
  text-align: center;

  & h2 {
    text-align: center;
  }
}

header a, header a:hover, header a:link, header a:visited {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", "Playfair Display-fallback";
  letter-spacing: 0.3px;
}

h1 {
  letter-spacing: 1.5px;
}

.heroCTAs {
  text-align: center;
}

button, .linkButton, .linkButton:link, .linkButton:visited {
  text-decoration: unset;
}

footer {
  font-style: italic;
  text-align: center;
}
